import { useState, useContext, useCallback, useEffect } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import Banner from "./banner.png";
import Opensea from "./opensea.svg";
import {ReactComponent as Twitter} from "./twitter.svg";

function App() {
  const [count, setCount] = useState(1);
  const [mintedCount, setMintedCount] = useState(0);
  const { connectBrowserWallet, mint, userData, transactionHash, contractDetails } =
    useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  useEffect(() => {
    if(!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)))
    }
  }, [contractDetails])

  return (
    <>
      <div className="greed-container">
        <div className="greed-links">
          <a href="https://opensea.io/collection/greedy-goblins-wtf" target="_blank" rel="noreferrer" className="greed-link-item">
            <img src={Opensea} alt="Opensea Link" />
          </a>
          <a href="https://twitter.com/_GreedyGoblins" target="_blank" rel="noreferrer" className="greed-link-item greed-twitter">
            <Twitter/>
          </a>
        </div>
        <p className='extreme'>{`5 more goblinz -> raffle 5 eth`}</p>
        <img src={Banner} alt="Greedy Goblin" />
        <div className="greed-form">
          <h1 className="f-shadow">Greedy Goblins</h1>
          <h2>SOLD OUT</h2>
          {!!contractDetails && (
            <p className="counter">
              {mintedCount} / 10000
            </p>
          )}
          {!!userData ? (
            <>
              <div className="greed-form-counter">
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="minus"
                >
                  -
                </button>
                <span> {count} </span>
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="plus"
                >
                  +
                </button>
                <button
                  onClick={handleCustomMint}
                  type="button"
                  className="claim-btn"
                >
                  Mint
                </button>
              </div>
              <div className="greed-claimers">
                <button
                  onClick={() => mint(3)}
                  type="button"
                  className="claim-btn"
                >
                  Mint 3
                </button>
                <button
                  onClick={() => mint(5)}
                  type="button"
                  className="claim-btn"
                >
                  Mint 5
                </button>
                <button
                  onClick={() => mint(10)}
                  type="button"
                  className="claim-btn"
                >
                  Mint 10
                </button>
              </div>
              {transactionHash && (
                <a
                  href={`https://etherscan.io/tx/${transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                  className="claim-btn"
                >
                  See transaction
                </a>
              )}
            </>
          ) : (
            <button
              type="button"
              className="connect-wallet"
              onClick={connectBrowserWallet}
            >
              Connect Wallet
            </button>
          )}

          <p>Be greedy as fack and grab 1 free goblin ffs <br/> 0.0069  for Greedy minters </p>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
